import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FullWidhtCarousel from './FullWidthCarousel';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import Animated from 'animated/lib/targets/react-dom';
import Stepper2 from './Stepper2';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme =>
  createStyles({
    root: {
      justifyContent: 'center',
      position: 'relative',
    },
    invertedBackground: {
      //   backgroundColor: '#181A38',
    },
    slide: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      display: 'flex',

    },
    swipableViewsWrap: {
      width: '100vw',
      [theme.breakpoints.up(960)]: {
        width: '100%',
      },
    },

    stepperWrap: {
      position: 'absolute',
      bottom: '35px',
      left: '0',
      right: '0',
      [theme.breakpoints.up(767)]: {
        bottom: '45px',
      },
    }
  });

const componentConstants = {
  autoplaySpeed: 4000,
  autoplayStopSpeed: 99999999999999,
};

class FullWidthCarouselSlider extends React.Component {
  constructor() {
    super();

    this.state = {
      interval: componentConstants.autoplayStopSpeed,
      activeStep: 0,
      position: new Animated.Value(0),
      preventSlide: false,
    };

  }

  handleStepChange = nextStep => {
    Animated.spring(this.state.position, { toValue: nextStep }).start();

    this.setState({ activeStep: nextStep });
  };

  handleSwitch = (index, type) => {
    if (type === 'end') {
      Animated.spring(this.state.position, { toValue: index }).start();
      return;
    }

    Animated.spring(this.state.position, { toValue: index }).start();
  };

  render() {
    const { items, inverted, largeImage, mediumImage, classes, theme } = this.props;
    const { activeStep, position } = this.state;

    // set reference to our scrolling element
    let setRef = (el) => {
      if( el ) {
        this.viewElement = el.refs.node;
      }
    };

    return (
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classNames(classes.root, {
          [classes.invertedBackground]: inverted,
        })}
      >
        <AutoPlaySwipeableViews
          className={classes.swipableViewsWrap}
          interval={this.state.interval}
          springConfig={{
            duration: '800ms',
            easeFunction: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          onSwitching={this.handleSwitch}
          enableMouseEvents
          threshold={5}
        >
          {items.map((step, currentIndex) => {
            const inputRange = items.map((_, i) => i);
            const opacity = position.interpolate({
              inputRange,
              outputRange: inputRange.map(i => {
                return currentIndex === i ? 1 : 1;
              }),
            });

            return (
              <Animated.div
                ref={setRef}
                key={String(currentIndex)}
                style={Object.assign(
                  {
                    opacity,
                    // transform: [{ scale }, { translateX }/*, { rotateY }*/],
                  },
                  styles.slide
                )}
              >
                <FullWidhtCarousel
                  inverted={inverted}
                  largeImage={largeImage}
                  mediumImage={mediumImage}
                  {...step}
                />
              </Animated.div>
            );
          })}

        </AutoPlaySwipeableViews>
        <Grid item className={classes.stepperWrap}>
          <Stepper2
            steps={items.length}
            activeStep={activeStep}
            inverted={inverted}
            onDotClick={this.handleStepChange}

          />
        </Grid>
      </Grid>
    );
  };

  //attach our function to document event listener on scrolling whole doc
  componentDidMount() {
    document.addEventListener("scroll", this.isInViewport);
  };

  //do not forget to remove it after destroyed
  componentWillUnmount() {
    document.removeEventListener("scroll", this.isInViewport);
  };

  //our function which is called anytime document is scrolling (on scrolling)
  isInViewport = () => {

    //get how much pixels left to scrolling our ReactElement
    const top = this.viewElement.getBoundingClientRect().top - window.innerHeight;
    //here we check if element top reference is on the top of viewport
    /*
    * If the value is positive then top of element is below the top of viewport
    * If the value is zero then top of element is on the top of viewport
    * If the value is negative then top of element is above the top of viewport
    * */
    if (top <= 0 ) {
      if( this.state.interval !== componentConstants.autoplaySpeed ) {
        this.setState({ interval: componentConstants.autoplaySpeed });
      }

    } else {
      this.setState({ activeStep: 0 });
    }
  };
}

export default withStyles(styles, { withTheme: true })(FullWidthCarouselSlider);
