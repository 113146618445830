import React from 'react';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { range } from 'lodash';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    root: {
      padding: 8,
    },
    standardDot: {
      padding: 0,
      width: 15,
      height: 15,
      margin: 10,
      borderRadius: '50%',
      backgroundColor: 'transparent',
      border: '2px solid white',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'white',
        transition: '0.4s ease-in',
        [theme.breakpoints.down(1280)]: {
          backgroundColor: 'transparent',
        },
      },
      [theme.breakpoints.up(767)]: {
        width: 20,
        height: 20,
      },  
      '&:focus': {
        outline: 0,
      }
    },

    activeDot: {
        backgroundColor: 'white !important'
    },
  });

const Stepper2 = ({ steps, activeStep, classes, inverted, onDotClick }) => (
  <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
    <Grid item>
      {range(steps).map((stepIndex) => (
        <button
          key={String(stepIndex)}
          type="button"
          onClick={() => onDotClick(stepIndex)}
          className={classnames(classes.standardDot,{
            [classes.dot]: (!inverted && activeStep !== stepIndex),
            [classes.invertedDot]: (inverted && activeStep !== stepIndex),
            [classes.activeDot]: activeStep === stepIndex,
          })}
        />
      ))}
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true})(Stepper2);