import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { RenovationServiceImages } from '../imageURLs';

//Data
import keyProcesses from '../../data/renovation/key-processes.json';

import CenterContentNarrow from '../components/CenterContentNarrow';
import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CtaBlock from '../components/CtaBlock';
import CTAButton from '../components/CTAButton/CTAButton';
import InnerImage from '../components/InnerImage/innerImage';
import FullWidthCarouselSlider from '../components/Carousel/FullWidthCarouselSlider';
import ProcessText from '../components/singleproject/processText'
import Description from '../components/Description';
import TitleSmaller from '../components/TitleSmaller';
import ServiceIntroSection from '../components/ServiceIntroSection';



const heroSliderItems = [
  {
    imagePath: RenovationServiceImages.hero1,
  },
  {
    imagePath: RenovationServiceImages.hero2,
  },
  {
    imagePath: RenovationServiceImages.hero3,
  },
];

const styles = theme =>
  createStyles({

    heroSliderWrap: {
      position: 'relative',
      top: '0',
      left: '0',
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    twoColumnsWrap: {
      alignItems: 'flex-start',  
      flexWrap: 'wrap',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingTop: 40,
        paddingBottom: 125,
      },
    },

    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 52%',
        maxWidth: '52%',
        paddingRight: 110,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '40px 135px 40px 0'
      },
    },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 48%',
        maxWidth: '48%',
      },
    },

    rightColumnText: {
      backgroundColor: theme.palette.grey.main,
      padding: '40px',
      alignItems: 'flex-start',
      order:1,
      [theme.breakpoints.up(960)]: {
        order: 0
      },
    },

    rightColumnImage: {
      maxHeight: '506px',
      overflow: 'hidden',
      [theme.breakpoints.up(767)]: {
        maxHeight: '570px'
      },
    },

  });

const RenovationServicePage = ({classes,toggleQuoteComp,children}) => (

<React.Fragment>

<SEO
      title="Turf Renovation & Maintenance - Twin View Turf - Producer of Australia’s Best Lawns"
      keywords={[
        `Turf care guides`,
        `care guides`,
        `Turf`,
        `Lawns`,
        `Best Lawns`,
        `Australia’s Best Lawns`,
        `lawn producer`,
      ]}
/>

    {/* HERO SECTION START */}
      <Grid
        container
        className={classes.heroSliderWrap}
      >
        <FullWidthCarouselSlider largeImage items={heroSliderItems}  />
      </Grid>

    {/* SERVICES - INTRO SECTION */}
    <ServiceIntroSection
      title="Renovation & Maintenance"
      subTitle="For world class playing surfaces."
      text="We offer a complete range of sports turf renovation and maintenance solutions which can be tailored to your needs."
    ></ServiceIntroSection>


      {/* TWO COLUMNS SECTION START */}
        <CenterContentNarrow>
        <Grid
          container
          direction="column"
          className={classes.twoColumnsWrap}
        >
          {/* left column */}
          <Grid
            container
            direction="column"
            className={classes.leftColumnWrap}
          >

          {keyProcesses.map(process => (
            <ProcessText
              key={process.title}
              processTitle={process.title}
              processDesc={process.desc}
            />
            ))}

          </Grid>
          {/* right column */}
          <Grid
            container
            direction="column"
            className={classes.rightColumnWrap}
          >
            {/* right column-text */}
            <Grid
              container
              direction="column"
              className={classes.rightColumnText}
            >

            <TitleSmaller
              title="Premium Services"
            ></TitleSmaller>

            <Description 
              descText="Our services can be tailored to suit a wide range of applications from sporting venues, wickets and golf courses to school ovals and public spaces. Our experienced operators and extensive range of machinery guarantees a high quality renovation."
            ></Description>

            </Grid>

            {/* right column image */}
            <Grid
              container
              className={classes.rightColumnImage}
            >
              <InnerImage
                image={{ className: classes.img, src: RenovationServiceImages.thumb }}
              ></InnerImage>
            </Grid>

          </Grid>
        </Grid>
      </CenterContentNarrow>

    {/* CTA SECTION START */}
    <CtaBlock title="Planning a field construction?">
    <CTAButton
      inverted
      ctaHref="/contact"
      ctaText="Get a quote"
    />
    </CtaBlock>

  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(RenovationServicePage));