import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';


const styles = theme =>
  createStyles({
    titleSmaller: {
      fontSize: '38px',
      fontWeight: '600',
      marginBottom: 34,
      lineHeight: '40px',
      letterSpacing: 1,
    },

  });

const TitleSmaller = ({classes,title}) => (

    <Typography
      variant="h4"
      color="primary"
      className= {classes.titleSmaller}
  >
    {title} 
  </Typography>

  );

  export default withStyles(styles, { withTheme: true })(TitleSmaller);