import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';


const styles = theme =>
  createStyles({
    descText: {
      fontSize: 20,
      marginBottom: 15,
      lineHeight: '22px',
      fontWeight: '300',
      letterSpacing: '0.5px'
    }
  });

const Description = ({classes,descText}) => (

  <Typography
    variant="body1"
    color="primary"
    className= {classes.descText}
  > 
    {descText}
  </Typography>

  );

  export default withStyles(styles, { withTheme: true })(Description);