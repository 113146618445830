import React from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Image from '../Image';

const styles = theme =>
  createStyles({
    root: {
    maxHeight: '100%',
    },
    innerImageWrap: {
      width: '100%',
      height: '100%',
      willChange: 'transform',
      border: 0,
    },
    image: {
      width: '100%',
      height: '100%',
      maxHeight: '660px',
      objectFit: 'cover',
    },
    copy: {
      padding: '0 50px',
      minHeight: '48px',
      maxWidth: 340,
      margin: '0 auto',
    },
    invertedBackground: {
    //   backgroundColor: '#181A38',
    },
    invertedFontColor: {
      color: '#fff',
    },
    largeImage: {
      width: '100%',
      height: '506px',
      objectFit: 'cover',
      [theme.breakpoints.up(767)]: {
        height: '660px',
      },         
    },

    mediumImage: {
      width: '100%',
      height: '506px',
      objectFit: 'cover',
      [theme.breakpoints.up(767)]: {
        height: '560px',
      },   
    }

  });

const FullWidthCarousel = ({ classes, imagePath, inverted = false, largeImage = false, mediumImage = false }) => (
  
  <Grid
    container
    item
    justify="center"
    direction="column"
    className={classNames(classes.root, {
      [classes.invertedBackground]: inverted,
    })}
  > 

    <Grid container item justify="center">
      <Grid item className={classNames(classes.innerImageWrap, { [classes.largeImage]: largeImage },{ [classes.mediumImage]: mediumImage })}>
        <Image className={classNames(classes.image)} relativePath={imagePath} />
      </Grid>
    </Grid>
  </Grid>

);

export default withStyles(styles, { withTheme: true })(FullWidthCarousel);